<template>
  <div class="d-flex align-center">
    <v-icon :color="iconColor" class="mr-3">{{ icon }}</v-icon>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "IconValue",
  props: {
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
